import { useState, useEffect } from "react";
import "../App.scss";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import MuiAccordion from '@mui/material/Accordion';
import { Box } from '@mui/material';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import prevBtnImg from "../Assets/Images/prev.png"
import nextBtnImg from "../Assets/Images/next.png"

function Tabs(props) {
    
	const [isLoading, setIsLoading]   						= useState(true);

	const [toggleState, setToggleState] 					= useState(1);
	const [showMoreDetails, setShowMoreDetails] 	= useState(false);
	const [questions, setQuestions] 							= useState([]);
	const [sections, setSections] 								= useState([]);
	const [toggleMore, setToggleMore] 						= useState(0);	
	const [section1saligned, setSection1sAligned]	= useState([]);
	const [section1sout, setSection1sOut]					= useState([]);
	const [section2saligned, setSection2sAligned]	= useState([]);
	const [section2sout, setSection2sOut]					= useState([]);
	const [section3saligned, setSection3sAligned]	= useState([]);
	const [section3sout, setSection3sOut]					= useState([]);
	const [section4saligned, setSection4sAligned]	= useState([]);
	const [section4sout, setSection4sOut]					= useState([]);
	
	const [section5saligned, setSection5sAligned]	= useState([]);
	const [section5sout, setSection5sOut]					= useState([]);
	const [section6saligned, setSection6sAligned]	= useState([]);
	const [section6sout, setSection6sOut]					= useState([]);
	const [section7saligned, setSection7sAligned]	= useState([]);
	const [section7sout, setSection7sOut]					= useState([]);
	const [section8saligned, setSection8sAligned]	= useState([]);
	const [section8sout, setSection8sOut]					= useState([]);
	const [section9saligned, setSection9sAligned]	= useState([]);
	const [section9sout, setSection9sOut]					= useState([]);
	
	const [currentTabIdx, setCurrentTabIdx]				= useState(0);

	useEffect(() => {
    updateContent(); 
  }, []);

	useEffect(() => {
		setToggleState(currentTabIdx + 1);
	}, [currentTabIdx]);

	function getMostCommonAnswer(arr) {

		let count = {};
		let maxCount = 0;
		let mostCommonAns = null;
		
		for (let i = 0; i < arr?.length; i++) {
			const answer = arr?.[i]?.answer;
			count[answer] = (count[answer] || 0) + 1;
			
			if (count[answer] > maxCount) {
				maxCount = count[answer];
				mostCommonAns = answer;
			}
		}
		return mostCommonAns;
	}
	

	const updateContent = () => {

		let members 					= props.members;
		let questions 				= props.questions;
		let sections 					= props.sections;

		let sectionIds 		= [];
		sections.forEach((section) => {
			sectionIds.push(section.id);
		});

		questions.forEach(question => {
			
			let answer1 				= 0;
			let answer2 				= 0;
			let answer3 				= 0;
			let answer4 				= 0;
			let answer5 				= 0;
			let aligned 				= false;
			let alignedAnswer 	= 0;
			let memAnswers 			= [];
			
			members.forEach(member => {
				let answers 			= member.answers.find(function(obj) { return obj.question_id == question.id; });
				memAnswers.push({
					name: member.fullName,
					answer: answers.answer
				});
				if(answers.answer == 1) { answer1++; }
				if(answers.answer == 2) { answer2++; }
				if(answers.answer == 3) { answer3++; }
				if(answers.answer == 4) { answer4++; }
				if(answers.answer == 5) { answer5++; }
			});

			let answerArray 		= [
				{id: 1, margin: answer1 / members.length},
				{id: 2, margin: answer2 / members.length},
				{id: 3, margin: answer3 / members.length},
				{id: 4, margin: answer4 / members.length},
				{id: 5, margin: answer5 / members.length}
			];
			answerArray.sort((a, b) => b.margin - a.margin);
			// if(answerArray[0].margin > 0.5) {
			// 	aligned 					= true;
			// 	alignedAnswer 		= answerArray[0].id;
			// }

			let zeroAnswers = 0;
			answerArray.forEach(mem => {
				if(mem.margin == 0) {
					zeroAnswers++;
				}
			});

			if(zeroAnswers > 3) {
				aligned = true;
			} else if(zeroAnswers > 2) {
				let newAnswerArray = answerArray.sort((a, b) => a.id - b.id);
				let memDiff = newAnswerArray.filter(function (obj) { return obj.margin != 0 && obj.id != 3 });
				// console.log(memDiff);
				if(memDiff.length > 1 && memDiff[1].id - memDiff[0].id == 1) {
					aligned = true;
				}
			}

			question.aligned 		= aligned;
			question.answerArray = answerArray;
			// question.alignedAns	= alignedAnswer;
			question.alignedAns	= getMostCommonAnswer(memAnswers);
			question.members 		= memAnswers;
			question.open 			= false;

		});

		let section1Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[0] && obj.aligned; });
		setSection1sAligned(section1Aligned);
		let section1Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[0] && !obj.aligned; });
		setSection1sOut(section1Out);
		let section2Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[1] && obj.aligned; });
		setSection2sAligned(section2Aligned);
		let section2Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[1] && !obj.aligned; });
		setSection2sOut(section2Out);
		let section3Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[2] && obj.aligned; });
		setSection3sAligned(section3Aligned);
		let section3Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[2] && !obj.aligned; });
		setSection3sOut(section3Out);
		let section4Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[3] && obj.aligned; });
		setSection4sAligned(section4Aligned);
		let section4Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[3] && !obj.aligned; });
		setSection4sOut(section4Out);
		
		let section5Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[4] && obj.aligned; });
		setSection5sAligned(section5Aligned);
		let section5Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[4] && !obj.aligned; });
		setSection5sOut(section5Out);
		let section6Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[5] && obj.aligned; });
		setSection6sAligned(section6Aligned);
		let section6Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[5] && !obj.aligned; });
		setSection6sOut(section6Out);
		let section7Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[6] && obj.aligned; });
		setSection7sAligned(section7Aligned);
		let section7Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[6] && !obj.aligned; });
		setSection7sOut(section7Out);
		let section8Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[7] && obj.aligned; });
		setSection8sAligned(section8Aligned);
		let section8Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[7] && !obj.aligned; });
		setSection8sOut(section8Out);
		let section9Aligned		= questions.filter(function (obj) { return obj.sectionId == sectionIds[8] && obj.aligned; });
		setSection9sAligned(section9Aligned);
		let section9Out				= questions.filter(function (obj) { return obj.sectionId == sectionIds[8] && !obj.aligned; });
		setSection9sOut(section9Out);
		// setSection1(section1);

		setQuestions(questions);
		setSections(sections);
		setIsLoading(false);
		
	}

	const toggleTab = (index) => {
		setToggleState(index);
	};

	const toggleDetails = (id) => {
		if(id == toggleMore) {
			setToggleMore(0);
		} else {
			setToggleMore(id);
		}
	}

	const toggleMoreDisplay = (id) => {
		if(id == toggleMore) {
			return { display: "block" }
		} else {
			return { display: "none" }
		}
	}

	const toggleMoreIcon = (id) => {
		if(id == toggleMore) {
			return <RemoveIcon/>
		} else {
			return <AddIcon/>
		}
	}

	const toggles = () => {
		return	<Box className="icon-container-box" sx={{'.Mui-expanded & > .collapseIconWrapper': {display: 'none',},
				'.expandIconWrapper': {display: 'none',},
				'.Mui-expanded & > .expandIconWrapper': {display: 'block',},}}>
					<div className="collapseIconWrapper">
					<AddIcon />
					</div>
					<div className="expandIconWrapper">
					<RemoveIcon />
					</div>
				</Box>
	}

	const showAnswerText = (answer, questionId) => {
		// console.log(answer);
		let question			= questions.find(function (obj) { return obj.id == questionId; });
		// console.log(question.answers);
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		if(answerVal && answerVal.desktop_text) {
			<div className="answer false">{answerVal.desktop_text}</div>
		}
		
		 if(answer == 1) {
		 	return <div className="answer false">STRONGLY DISAGREE</div>
		 } else if(answer == 2) {
		 	return <div className="answer false">DISAGREE</div>
		 } else if(answer == 3) {
		 	return <div className="answer neutral">NEUTRAL</div>
		 } else if(answer == 4) {
		 	return <div className="answer true">AGREE</div>
		 } else if(answer == 5) {
		 	return <div className="answer true">STRONGLY AGREE</div>
		 }
	}

	const showIndividualAnswerText = (answer, questionId) => {
		
		let question			= questions.find(function (obj) { return obj.id == questionId; });
		let answerType 		= "green";
		// console.log(question);

		if(question.answers.length == 2) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "green"; }
		} else if(question.answers.length == 3) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "orange"; }
			else if(answer == 3) { answerType = "green"; }
		} else if(question.answers.length == 4) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "green"; }
			else if(answer == 4) { answerType = "green"; }
		} else if(question.answers.length == 5) {
			if(answer == 1) { answerType = "red"; }
			else if(answer == 2) { answerType = "red"; }
			else if(answer == 3) { answerType = "orange"; }
			else if(answer == 4) { answerType = "green"; }
			else if(answer == 5) { answerType = "green"; }
		}
		let answerVal			= question.answers.find(function (obj) { return obj.value == answer; });
		
		if(answerVal && answerVal.desktop_text) {
			if(answerType == "red") { 
				return <div className="answer false">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n").toUpperCase()}</div>
			} else if(answerType == "orange") {
				return <div className="answer neutral">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n").toUpperCase()}</div>
			} else if(answerType == "green") { 
				return <div className="answer true">{answerVal.desktop_text.replace(/<br\/\s*\\?>/g, "\r\n").toUpperCase()}</div>
			}
		}
	}

	if (isLoading) {
		return null;
	}
	
	console.log("sections", sections);
	const nextTab = () => {
		let sectionsLen = sections?.length;
		if(currentTabIdx < sectionsLen - 1) {	
			setCurrentTabIdx((prev) => prev + 1);
		} else setCurrentTabIdx(0);
	}
	
	const prevTab = () => {
		let sectionsLen = sections?.length;
		if(currentTabIdx > 0) {	
			setCurrentTabIdx((prev) => prev - 1);
		} else setCurrentTabIdx(sectionsLen - 1);
	}

	//remove html tags
	function removeTags(str) {
		if ((str === null) || (str === ''))
			return false;
		else
		str = str.toString();
		return str.replace(/<[^>]+>/ig, '');
	}


	console.log("props", props?.members, props?.questions, props?.sections);

	console.log("section1saligned", section1saligned); console.log("section1sout", section1sout);
	console.log("section2saligned", section2saligned); console.log("section2sout", section2sout);
	console.log("section3saligned", section3saligned); console.log("section3sout", section3sout);
	console.log("section4saligned", section4saligned); console.log("section4sout", section4sout);
	console.log("section5saligned", section5saligned); console.log("section5sout", section5sout);
	console.log("section6saligned", section6saligned); console.log("section6sout", section6sout);
	console.log("section7saligned", section7saligned); console.log("section7sout", section7sout);
	console.log("section8saligned", section8saligned); console.log("section8sout", section8sout);

	return (
		<div className="container-tabs">
			{/* <div className="tab-buttons">
				{sections.map((data, index) => {
					let toggleIndex = index + 1;
					return <button key={index} className={toggleState === toggleIndex ? "active" : ""} onClick={() => toggleTab(toggleIndex)}>{data.section}</button>
				})}
      </div> */}
			
			<div className="tab-change">
				<p>{currentTabIdx + 1}/{sections?.length}</p>
				<div className="tab-btn-container">
					<button style={{ background: "linear-gradient(180deg, #3884C6 0%, #5DB256 100%)", padding: "12px 16px 12px 14px" }} onClick={() => prevTab()}><img src={prevBtnImg} /></button>
					<span className="section-name">{sections[currentTabIdx]?.section}</span>	
					<button style={{ background: "linear-gradient(180deg, #3884C6 0%, #5DB256 100%)", padding: "12px 14px 12px 16px" }} onClick={() => nextTab()}><img src={nextBtnImg} /></button>
				</div>
			</div>
			
			

			<div className="tabs-content">
				<div className={toggleState === 1 ? "content  active-content" : "content"}>

					{
						section1saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section1saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section1sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">
								Analyze potential areas for better alignment
								{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section1sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}

				</div>

				<div className={toggleState === 2 ? "content  active-content" : "content"}>
					{
						section2saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section2saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section2sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section2sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>

				<div className={toggleState === 3 ? "content  active-content" : "content"}>
					{
						section3saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section3saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section3sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section3sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>
                
				<div className={toggleState === 4 ? "content  active-content" : "content"}>
					{
						section4saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section4saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section4sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section4sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>
				
				<div className={toggleState === 5 ? "content  active-content" : "content"}>
					{
						section5saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section5saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section5sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section5sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>
				
				<div className={toggleState === 6 ? "content  active-content" : "content"}>
					{
						section6saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section6saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section6sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section6sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>
				
				<div className={toggleState === 7 ? "content  active-content" : "content"}>
					{
						section7saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section7saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section7sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section7sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>
				
				<div className={toggleState === 8 ? "content  active-content" : "content"}>
					{
						section8saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section8saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section8sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section8sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>
				
				<div className={toggleState === 9 ? "content  active-content" : "content"}>
					{
						section9saligned.length > 0 ?
							<MuiAccordion className="align-details" defaultExpanded>
								<AccordionSummary className="green">Celebrate current areas of great alignment
								{toggles()}
								</AccordionSummary>
								<AccordionDetails sx={{padding: 0}}>
								{
									section9saligned.map((data, index) => (
										<div key={index} className="answered-detail no-wrap">
											{removeTags(data.question)}
											{showAnswerText(data.alignedAns, data.id)}
										</div>
									))
								}
								</AccordionDetails>
							</MuiAccordion>
						: null
					}
                        
					{
						section9sout.length > 0 ?
						<MuiAccordion className="align-details analyze" defaultExpanded>
							<AccordionSummary className="red">Analyze potential areas for better alignment
							{toggles()}
							</AccordionSummary>
							<AccordionDetails sx={{padding: 0}}>
							{
								section9sout.map((data, index) => (
									<div key={index} className="answered-detail" onClick={() => toggleDetails(data.id)}>
										<a className="more-toggle">
											{ toggleMoreIcon(data.id) }
										</a>
										<p>{removeTags(data.question)}</p>
										<div className="more-details" style={ toggleMoreDisplay(data.id) }>
											{
												data.members.map((member, index) => (
													<div key={index} className="more-row"><p>{member.name}</p> {showIndividualAnswerText(member.answer, data.id)}</div>											
												))
											}	
										</div>
									</div>
								))
							}
							</AccordionDetails>
						</MuiAccordion>
						: null
					}
				</div>




			</div>
		</div>
	);
}

export default Tabs;
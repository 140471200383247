import { useState, useEffect } from 'react';

import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const Question = props => {

  console.log("props", props);

  const [showSlider, setShowSlider]   = useState(false);
  const [progress, setProgress]       = useState(props?.currentValue);
  const [sliderWidth, setSliderWidth] = useState(90);
  const [maxWidth, setMaxWidth]       = useState(props.question?.answers?.length);
  const [runTimeoutFunc, setRunTimeoutFunc] = useState(false);

  useEffect(() => {
    if(runTimeoutFunc) { 
      const timer = setTimeout(() => {
        if(props.question.answer == 0 || !props.question.answer) {
          setShowSlider(false);
        }
      }, 500);
      return () => {
        setRunTimeoutFunc(false)
        clearTimeout(timer);
      }
    }

  }, [props.question.answer, runTimeoutFunc]);

  useEffect(() => {
    if((props.question.answer == 0 || !props.question.answer) && !progress) {
      setShowSlider(false);
    } 
    else if(props.question.answer > 0 || progress > 0) {
      setShowSlider(true);
    }
  }, [props.question.answer, progress])

  useEffect(() => {
    if(props.question.answer == 0) setProgress(0);
    else if(props.question.answer > 0) setProgress(props.question.answer);
  }, [props.question.answer])

  useEffect(() => {
    switch (props.question?.answers?.length) {
      case 2: setSliderWidth(36); break;
      case 3: setSliderWidth(56); break;
      case 4: setSliderWidth(76); break;
      case 5: setSliderWidth(85); break;
      case 6: setSliderWidth(90); break;
      default: break;
    }
    
    setMaxWidth(props.question?.answers?.length);


  }, [props, props.question.question, props.question.answer])

  // console.log("props.question.answer", props.question.answer);
  // console.log("progress", progress); console.log("showSlider", showSlider);


  const getLeft = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }
  
  const getLeftRadio = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 6;
      case 6: return index * 4;
      default: return index * 20;
    }
  }

  const getRadioWidth = (answers) => {

    switch (answers) {
      case 3: return {width: "70%", marginLeft: "7.5%"};
        // return { width: "70%" };
      case 5: return {width: "90%", marginLeft: "2.5%"};
      case 6: return {width: "100%", marginLeft: "0%"};
      default: return {width: "50%"};
    }
    // if(answers === 3) { return {width: "70%", marginLeft: "7.5%"}; }
    // return {width: "50%"};
  }

  const radioOptionWidth = (answers) => {
    switch (answers) {
      case 5: return "20%";
      case 6: return "16%";
      default: return "20%";
    }
  }
  

  const updateStep = (progressArg) => {
    setShowSlider(true);
    setTimeout(() => {setProgress(progressArg)}, 0);
    props.questionAnswered(progressArg);
    setRunTimeoutFunc(true);
  }

  const updateRadioStep = (progressArg) => {

    setTimeout(() => {setProgress(progressArg)}, 0);
    props.questionAnswered(progressArg);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const getLabelStyle = () => {
    if(window.innerWidth > 650) {

      if(props.question?.answers?.length === 5) {
        return { width: "100%", marginLeft: "-50%" };
      } else if(props.question?.answers?.length === 4) {
        return { width: "150%", marginLeft: "-75%" };
      } else if(props.question?.answers?.length === 3) {
        return { width: "200%", marginLeft: "-100%" };
      } else if(props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "150%", marginLeft: "-75%" };
      }
      // return { width: "14ch", marginLeft: "-7ch" };

    } else {

      if(props.question?.answers?.length === 6) {
        return { width: "9ch", marginLeft: "-4.5ch", wordBreak: "break-all" };}
      // if(props.question?.answers?.length === 5) {
      //   return { width: "120%", marginLeft: "-60%" };
      // } else if(props.question?.answers?.length === 4) {
      //   return { width: "150%", marginLeft: "-75%" };
      // } else if(props.question?.answers?.length === 3) {
      //   return { width: "300%", marginLeft: "-150%" };
      // } else if(props.question?.answers?.length === 2) {
      //   return { width: "500%", marginLeft: "-250%" };
      // } else {
      //   return { width: "150%", marginLeft: "-75%" };
      // }
      else return { width: "12ch", marginLeft: "-6ch" };
    }
    
  }

  const checkOpacity = (index) => {
    if(index === 0 && showSlider) return "0";
    else return "1";
  }

  const checkSelected = (value) => {
    if(progress === value) return "label active";
    else return "label";
  }

  const updateQuestion = (event, step) => {
    props.question.answerTxt = event.target.value;
    // setState({answerTxt: event.target.value});
    props.textAnswer(event.target.value);
    // return true;
  }

    return (
      <div>
        
        <div className="question">
          <p>{props.question?.question}</p>
        </div>

        {
          (() => {
            switch(props.question?.type) {
              
              case "textarea":
                return <>
                  <div className="textarea-field">
                    <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={props.question?.answerTxt} onChange={(e) => {updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                  </div>
                </>

              case "radio":
                return <>
                  <div className="radio-container">
                    <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                      { 
                        props.question?.answers?.map((data, index) => (
                          <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', width: radioOptionWidth(props?.question?.answers?.length) }} onClick={() => {
                            console.log("data.value", data.value);
                            updateRadioStep(data.value);
                          }}>
                          {/* <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                            {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                            <Radio checked={data.value === progress} value={progress} />
                            <p className="desktop" style={getLabelStyle()}>{data.desktop_text}</p>
                            <p className="mobile" style={getLabelStyle()}>{data.desktop_text}</p>
                          </div>
                        ))
                      }
                          {/* { 
                            props.question?.answers.map((data, index) => (
                              <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                              // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                            ))
                          } */}
                      
                    </div>
                  </div>
                </>

              default: 
                return <div className="slider-container" style={{ width: sliderWidth + '%' }}>
                  <div className="slider">
                    { showSlider ? <Slider
                      key={props.question?.id}
                      valueLabelDisplay="off"
                      step={1}
                      min={1}
                      max={maxWidth}
                      value={progress}
                      className="custom-slider"
                    /> : <div className="slider-none-adjust"></div> }
                    <div className="slider-points">
                    { 
                      props.question?.answers?.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
                      ))
                    }
                    </div>
                    <LinearProgress className="custom-bar" variant="determinate" value={0} />
                    
                  </div>
                  <div className="slider-labels">
                    { 
                      props.question?.answers?.map((data, index) => (
                        <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                          <p className="desktop" style={getLabelStyle()}>{data.desktop_text}</p>
                          <p className="mobile" style={getLabelStyle()}>{data.mobile_text}</p>
                        </div>
                      ))
                    }
                  </div>
                </div>
            }
          }).call(this)
        }
  
        {/* <div className="slider-container" style={{ width: state.sliderWidth + '%' }}>
          <div className="slider">
            { state.showSlider ? <Slider
              key={props.question.id}
              valueLabelDisplay="off"
              step={1}
              min={1}
              max={state.maxWidth}
              value={state.progress}
              className="custom-slider"
            /> : <div className="slider-none-adjust"></div> }
            <div className="slider-points">
            {/* { !state.showSlider ? 
              <a className="point" style={{ left: '0%'}} onClick={() => updateStep(1)}></a> : null } */}
              {/* { 
                props.question?.answers.map((data, index) => (
                  <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
                ))
              }
            </div>
            <LinearProgress className="custom-bar" variant="determinate" value={0} />
            
          </div>
          <div className="slider-labels">
            { 
              props.question?.answers.map((data, index) => (
                <div className={checkSelected(data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                  <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktop_text }}></p>
                  <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobile_text }}></p>
                </div>
              ))
            }
          </div>
        </div> */}
  
      </div>
    );
  }
  
export default Question;
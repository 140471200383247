import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/testimonial_sam.png';

const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>With so many areas to focus on as a church leader, it can be quite overwhelming to figure out if we’re really making progress in a particular aspect. This assessment helped us identify areas of strength, as well as areas of opportunity for better alignment and vision casting.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} alt="pastor" />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Senior Pastor, New Life Church</span>
          </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;

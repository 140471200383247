import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import insightsIcon from '../Assets/Images/insights-icon.png';
import alignmentIcon from '../Assets/Images/alignment-icon.png';
import directionIcon from '../Assets/Images/direction-icon.png';
import Testimonial from './Testimonial';

const InviteInfo = (props) => {

  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this test</h3>
        <p>Gain new insights into your church's alignment by inviting your team to take this test.</p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={insightsIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Measure your church's score in critical areas of ministry
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Begin to align around a strategy for progress as a team
          </div>

          <div className="reason">
            <img src={directionIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Take the next step to chart your church's best course forward
          </div>

        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

        <Testimonial />


      </div>
      
      <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div>
      
    </div>
  );
}

export default InviteInfo;

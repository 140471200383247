import React, { useState, useEffect } from 'react';
import ReactDOMServer from "react-dom/server";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

import prevArrow from '../Assets/Images/prev-arrow.svg';
import nextArrow from '../Assets/Images/next-arrow.svg';

import testimonialImage from '../Assets/Images/testimonial.jpg';
import quotationImage from '../Assets/Images/quotation.svg';

import insightsIcon from '../Assets/Images/insights-icon.png';
import alignmentIcon from '../Assets/Images/alignment-icon.png';
import directionIcon from '../Assets/Images/direction-icon.png';
import downloadIcon from '../Assets/Images/download-icon.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import '../App.scss';
// import ResultBarRainbow from '../Components/Results/ResultBarRainbow';
import ResultsSectionBarGraph from '../Components/Results/ResultsSectionBarGraph';
import InviteInfo from './InviteInfo';


const Results = (props) => {

  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const stuff = useParams();
  console.log(stuff);

  const [isLoading, setIsLoading]     = useState(true);
  const [isMember, setIsMember]       = useState(false);
  const [score, setScore]             = useState(0);
  const [name, setName]               = useState("");
  const [apiData, setApiData]         = useState(null);
  const [pdfClass, setPdfClass]       = useState(false);

  useEffect(() => {
    // document.body.style.backgroundColor = "#1F2A44";
    document.getElementById('assessment-title').style.color = "#FBF9F2";
    fetchResults();
  }, []);

  const fetchResults = () => {

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id, {
			method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' }
		}).then(response => {
			return response.json();
		}).then(data => {
      console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
        setApiData(data);
				setScore(data.score);
				setName(data.name);
        setIsMember(data.isTeamMember);
			} else {
				// setOpenSnackbar(true);
			}
		});
  }

  const source = apiData?.source;
  console.log("source", source);

  const subScore1 = parseInt(apiData?.subScore1);
  const subScore2 = parseInt(apiData?.subScore2);
  const subScore3 = parseInt(apiData?.subScore3);
  const subScore4 = parseInt(apiData?.subScore4);
  const subScore5 = parseInt(apiData?.subScore5);
  const subScore6 = parseInt(apiData?.subScore6);
  const subScore7 = parseInt(apiData?.subScore7);
  const subScore8 = parseInt(apiData?.subScore8);
  const subScore9 = parseInt(apiData?.subScore9);

  // console.log("apiData", apiData);
  const subscores = { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 };

  // console.log("maxx", (Math.max(parseInt(Object.values(subscores)))));
  // console.log("sorted", (Array.from(Object.values(subscores))));

  const showTitleText = () => {
    // if(score > 70) {
    //   return <h1>Your church exceeds expectations</h1>
    // } else if(score > 45) {
    //   return <h1>Your church meets expectations</h1>
    // } else {
    //   return <h1>Your church needs improvement</h1>
    // }

    return <h1>{apiData?.name?.trim()}, here's your church score</h1>
  }

  // const showScoreText = () => {
  //   if(score > 70) {
  //     return <div className="result-text">
  //       Congratulations! It looks like your church is doing a great job at ensuring it is prepared for future evangelism.
  //     </div>
  //   } else if(score > 45) {
  //     return <div className="result-text">
  //       While you are on a journey to ensuring your church is prepared for future evangelism, there's room for reaching out to more of the unchurched in your community.
  //     </div>
  //   } else {
  //     return <div className="result-text">
  //       It looks like your church needs to work towards reaching out to the unchurched in your community.
  //     </div>
  //   }
  // }

  const showInvite = () => {
    if(isMember) {
      return null;
    } else if (props?.showInvite === false) {
      return null;
    } else {
      return ( <InviteInfo /> )
    }
  }

  const showTeamMember = () => {
    if(isMember) {
      return <div className="team-container">
        <h3>Thank you for taking this test!</h3>
        Please get in touch with your leader for the next step.<br/><br/>
      </div>
    } else {
      return null;
    }
  }

  // const getLeftMargin = () => {
  //   if(window.innerWidth < 800 && score > 95) {
  //     return { left: 90 + '%'}
  //   } else if(window.innerWidth < 800 && score < 5) {
  //     return { left: 10 + '%'}
  //   } else {
  //     return { left: score + '%'};
  //   }
  // }

  // const showSchedule = () => {
  //   if(isMember) {
  //     return null;
  //   } else {
  //     return <div className="schedule-container">
  //       Schedule a call with Carey to debrief the results of your test and ensure your
  //       church is prepared with next steps for future evangelism.
  //       <div className="cta-btn">
  //         <a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
  //           <Button>TALK WITH CAREY</Button>
  //         </a>
  //       </div>
  //     </div>
  //   }
  // }

  const showLegacyInvite = () => {
    if(isMember) {
      return null;
    } else {
      // return <div className="invite-legacy-container">
      //   Invite your team to take the test and begin to align around an actionable strategy to grow toward a greater level of church health!
      //   <div className="cta-btn">
      //     <Link to={"/invite-team/" + id} target="_blank">
      //       <Button>INVITE MY TEAM</Button>
      //     </Link>
      //   </div>
      // </div>
      
      return <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church’s mission!
</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div>
    }
  }

  const showSourceSection = () => {
    return <div className="source-section">
      <div className="source-info">
        <p>There's a way to turn your greatest challenge into your greatest opportunity and we've got just the plan for you!</p>
        <div className="cta-btn">
          <a href="https://churchfuel.com/join/" target="_blank" rel="noreferrer">
            <Button>SHOW ME HOW</Button>
          </a>
        </div>
      </div>
    </div>
  }

  if (isLoading) {
		return <div className="container results loading"><CircularProgress style={{ color: '#5DB256' }} size={60} /></div>
	}

  const exportPDF = () => {

    setPdfClass(true);
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=1200");
    document.querySelector("#root").classList.add("pdf-export-results");
    document.querySelector("div.App").setAttribute("id", "pdf-conversion");
    
    html2canvas((document.querySelector("#root")), { scale: 1.5 }).then(canvas => {
      // if you want see your screenshot in body.
      // document.body.appendChild(canvas);
      const imgData = canvas.toDataURL('image/svg');
      // const pdf = new jsPDF("p", "mm", "a4");
      const pdf = new jsPDF({ orientation: "p", unit: "px", format: "a4"});
      const imgProperties = pdf.getImageProperties(imgData);
      
      let pageWidth = pdf.internal.pageSize.getWidth();
      // let pageHeight = (imgProperties.height * pageWidth) / imgProperties.width;
      let pageHeight = pdf.internal.pageSize.getHeight();
      console.log(pageWidth, pageHeight);
      const widthRatio = pageWidth / canvas.width;
      // const heightRatio = pageHeight / canvas.height;
      const heightRatio = ((imgProperties.height * pageWidth) / imgProperties.width) / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;
      console.log(canvasWidth, canvasHeight);
      
      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      
      // console.log(canvas);
      // pdf.addImage(imgData, 'PNG', marginX, marginY, canvasWidth, canvasHeight);
      // pdf.addImage(imgData, 'PNG', marginX, 40, canvasWidth, canvasHeight);
      // pdf.addImage(imgData, 'JPEG', 0, 0, pageWidth, pageHeight);
      
      pdf.addImage(imgData, 'SVG', 0, 0, pageWidth, pageHeight, undefined, 'FAST');

      pdf.save("Individual Results.pdf");
      // window.location.reload();
    });
    
    document.querySelector("div.App").removeAttribute("id");
    document.querySelector("#root").classList.remove("pdf-export-results");
    document.querySelector('meta[name=viewport]').setAttribute("content", "width=device-width");
    setPdfClass(false);
    
  }

  return (
    <div>
      <div className="container results" id="result-individual">
        
        <div className="details-container" style={{ paddingTop: "1rem" }}>
        
          {showTitleText()}
          <ResultsSectionBarGraph {...{ subscores }} pdfClass={pdfClass} />

          {/* <ResultBarRainbow score={score} /> */}
          {/* {showScoreText()} */}

          {/*<div className="pdf-btn">
            <Button id="pdf-convert-btn" onClick={() => exportPDF()}> <img src={downloadIcon} className="download-icon" alt="download pdf" height="24px" width="24px" />&nbsp; Download Results</Button>
  </div>*/}
        </div>
        
      </div>
      
      {showInvite()       }
      {showTeamMember()   }
      
      {/* {showLegacyInvite() } */}
      {/* {showSourceSection() } */}
      {/* {showSchedule()} */}

    </div>
  );
}

export default Results;

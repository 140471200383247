import React from 'react'
import { LinearProgress } from '@mui/material';

// const ResultSectionProgressBars = ({ subscores }) => {

const titles = [
  { id: 1, title: "Messaging", subheader: "Are you communicating effectively with your audiences?" },
  { id: 2, title: "Experience", subheader: "Do you need to design a more engaging experience for your church?" },
  { id: 3, title: "Metrics", subheader: "Are you prioritizing the need to measure your ministry and staff metrics? " },
  { id: 4, title: "Data & Leadership", subheader: "Are you utilizing high-quality data reporting to support your vision and mission?" },
  { id: 5, title: "Data Analysis Abilities", subheader: "Should leveling up to analyze your church's data be your #1 focus?" },
  { id: 6, title: "Status Quo", subheader: "Do you need to redefine your church's indicators for progress and success?" },
  { id: 7, title: "Data Desire", subheader: "Is it time to shift your focus to utilizing tools that drive better decision-making?" },
  { id: 8, title: "Data & Source", subheader: "Do you need to better interpret data to translate this into actionable next steps?" },
  { id: 9, title: "Data Methodology", subheader: "Do you need to change the way your church collects and analyzes data?" },
];

const ResultSectionProgressBars = (props) => {

  let htmlContainsPDFClass = props?.pdfClass;

  // const { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 } = props?.subscores;
  // console.log(props.subscores);

  // console.log(subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9);

  // console.log(props);
  const unsortedSubscores = Object.values(props?.subscores);
  console.log(unsortedSubscores.forEach(val => console.log(typeof val, val)));
  // console.log(unsortedSubscores);

  const unsortedSubscoresWithTitles = titles.map((item, index) => {
    // console.log("log i", index, item);
    return {
      ...item,
      subscore: unsortedSubscores[index]
    }
  });

  // console.log(unsortedSubscoresWithTitles);

  const sortedSubscoresWithTitles = unsortedSubscoresWithTitles.sort((a, b) => b?.subscore - a?.subscore);

  // console.log(sortedSubscoresWithTitles);

  const calcLeft = (subscore) => {
    // return subscore === 100 ? (subscore - 61) : subscore < 15 ? (-42) : (subscore - 60);
    if(subscore === 100) return `calc(${subscore}% - 5ch)`;
    else if(subscore <= 15) return `${subscore}%`;
    else return `calc(${subscore}% - 4ch)`;
  }

  return (
    <div className="result-container">

      {/*<h2 className="result-title">{sortedSubscoresWithTitles[0]?.title}</h2>*/}
      <p className="result-info">We're hopeful this report will help you celebrate areas of success and identify areas of opportunity for stronger alignment as a church team!</p>
      <div className="result-sections non-pdf-result-sections">
        {/* {JSON.stringify(sortedSubscoresWithTitles)} */}
        {sortedSubscoresWithTitles?.map((item, index) => {
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <LinearProgress variant="determinate" value={item?.subscore} />
                <span style={{ position: "absolute", textAlign: "left", left: calcLeft(item?.subscore), top: "0px", margin: 0, zIndex: 999999, fontWeight: 700, fontSize: "1rem", color: "white" }}>{item?.subscore}%</span>

              </div>
            </div>
          )
        })}
      </div>

      <div className="result-sections pdf-result-sections">
        {sortedSubscoresWithTitles?.map((item, index) => {
            
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <div className="custom-pdf-bar" value={item?.subscore}>
                  <div className="custom-pdf-bar-progress" 
                    style={{ width: `${item?.subscore}%` }}>

                    <p className="score-inside-progress-bar" 
                      style={{ textAlign: "right", width: `calc(100% - 8px)`, 
                      margin: 0, padding: "0px 0px 0.25rem 0px", zIndex: 999999, fontWeight: 700, fontSize: "0.925rem", color: "white" }}>{item?.subscore}%
                    </p>

                  </div>
                </div>
                
              </div>              
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ResultSectionProgressBars